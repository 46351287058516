import { h } from 'preact';

const Modal = ({ title, setModalOpen, isModalOpen, children }) => {
  const close = e => {
    e.preventDefault()
    setModalOpen()
  }

  return <div class={`modal ${isModalOpen ? 'active' : ''}`} id="modal-id">
    <a href="#close" class="modal-overlay" aria-label="Close" onClick={close} />
    <div class="modal-container">
      <div class="modal-header">
        <a href="#close" class="btn btn-clear float-right" aria-label="Close" onClick={close} />
        <div class="modal-title h5 text-center">{title}</div>
      </div>
      <div class="modal-body">
        {children}
      </div>
    </div>
  </div>
}

export default Modal
