import { h } from 'preact'
import { useRef } from 'preact/hooks'
import { route } from 'preact-router'
import { Link } from 'preact-router/match'
import { checkTextEvent } from '../helper'

let timer

const Header = ({ user, logout }) => {
  const searchInpRef = useRef()
  const searchIcon = useRef()

  const searchValue = () => {
    const page = window?.location?.pathname?.match(/networks|servers|nodes|peerings|transfers|rent/)?.[0] || 'networks'
    const { value } = searchInpRef.current
    route(`/${page}/${value}`)
    searchIcon.current.classList.remove('loading')
  }
  
  const search = e => {
    searchIcon.current.classList.add('loading')
    if (checkTextEvent(e)) {
      searchValue()
    } else {
      if (timer) clearTimeout(timer)
      timer = setTimeout(searchValue, 300)
    }
  }

  const clear = () => {
    searchInpRef.current.value = ''
    const page = window?.location?.pathname?.match(/networks|servers|nodes|peerings|transfers|rent/)?.[0] || 'networks'
    route(`/${page}`)
  }

  return <header class="navbar hide-sm">
    {user?.nick ?
      <>
        <section class="navbar-section">
          <Link activeClassName="active" className="navbar-brand mr-2" href={`/profile/${user.nick}`} >{user.nick.toUpperCase()}</Link>
          <Link activeClassName="active" href="/networks">Networks</Link>
          <Link activeClassName="active" href="/servers">Servers</Link>
          <Link activeClassName="active" href="/nodes">Nodes</Link>
          <Link activeClassName="active" href="/peerings">Peering</Link>
          <Link activeClassName="active" href="/transfers">Transfers</Link>
          <Link activeClassName="active" href="/rent">Rent</Link>
        </section>
        <section class="navbar-section">
          <div class="input-group input-inline has-icon-right">
            <input class="form-input" ref={searchInpRef} type="text" placeholder="search" onKeyUp={search} autoFocus defaultValue={window?.location?.pathname?.split('/')?.[2]} />
            <i ref={searchIcon} class="form-icon icon icon-cross" onClick={clear} />
          </div>
          <button class="btn btn-primary input-group-btn" onClick={search}>Search</button>

          <Link activeClassName="active" className="ml-2" onClick={logout} style={{ display: 'flex', 'align-items': 'center' }}>
            <img src="/assets/icons/logout.svg" />
          </Link>
        </section>
      </>
      :
      <>
        <h1>MIKE</h1>

        <section class="navbar-section">
          <Link activeClassName="active" href="/">Login</Link>
        </section>
      </>
    }
  </header>
}

export default Header
