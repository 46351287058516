import { h } from 'preact';
import { useRef, useEffect } from 'preact/hooks'
import { route } from 'preact-router';
import { Link } from 'preact-router/match';

import { checkTextEvent } from '../helper'

let timer

const Sidebar = ({ logout, children }) => {

	const searchInpRef = useRef()
	const searchIcon = useRef()
	const sidebarRef = useRef()

  const searchValue = () => {
    const page = window?.location?.pathname?.match(/networks|servers|nodes|peerings|transfers|rent/)?.[0] || 'networks'
    const { value } = searchInpRef.current
    route(`/${page}/${value}`)
		searchIcon.current.classList.remove('loading')
		toggleSidebar()
  }
  
  const search = e => {
		searchIcon.current.classList.add('loading')
    if (checkTextEvent(e)) {
      searchValue()
    } else {
      if (timer) clearTimeout(timer)
      timer = setTimeout(searchValue, 300)
    }
  }

  const clear = () => {
    searchInpRef.current.value = ''
    const page = window?.location?.pathname?.match(/networks|servers|nodes|peerings|transfers|rent/)?.[0] || 'networks'
    route(`/${page}`)
  }

	const toggleSidebar = (e) => {
		e?.preventDefault()
		sidebarRef.current.classList.toggle('active')
	}

	const handleClickOutside = (e) => {
		if (sidebarRef.current && sidebarRef.current.className.match(/active/) && (e.target?.parentNode?.className === 'menu-item' || !sidebarRef.current.contains(e.target))) {
			toggleSidebar(e)
		}
	}

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside)
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [sidebarRef])

	return (
		<div class="off-canvas">
			<a class="off-canvas-toggle btn btn-link btn-action ml-1 mt-1" href="#sidebar" onClick={toggleSidebar}>
				<i class="icon icon-menu"></i>
			</a>

			<div ref={sidebarRef} class="off-canvas-sidebar show-sm">
				<>
					<ul class="menu menu-nav">
						<li class="menu-item">
							<Link activeClassName="active" href="/networks">Networks</Link>
						</li>
						<li class="menu-item">
							<Link activeClassName="active" href="/servers">Servers</Link>
						</li>
						<li class="menu-item">
							<Link activeClassName="active" href="/nodes">Nodes</Link>
						</li>
						<li class="menu-item">
							<Link activeClassName="active" href="/peerings">Peerings</Link>
						</li>
						<li class="menu-item">
							<Link activeClassName="active" href="/transfers">Transfers</Link>
						</li>
						<li class="menu-item">
							<Link activeClassName="active" href="/rent">Rent</Link>
						</li>
						
						<li class="menu-item">
							<div class="input-group input-inline has-icon-right">
								<input class="form-input" ref={searchInpRef} type="text" placeholder="search" onKeyUp={search} autoFocus defaultValue={window?.location?.pathname?.split('/')?.[2]} />
								<i ref={searchIcon} class="form-icon icon icon-cross" onClick={clear} />
							</div>
						</li>
						<li class="menu-item">
							<Link activeClassName="active" onClick={logout}>
								<span>Logout</span>
								<img style={{ verticalAlign: 'text-bottom', marginLeft: '0.2rem' }} height={20} src="/assets/icons/logout.svg" />
							</Link>
						</li>
					</ul>
				</>
			</div>

			<a class="off-canvas-overlay" onClick={toggleSidebar}></a>

			<div id="content" class="off-canvas-content">
				{children}
			</div>
		</div>
	)
}

export default Sidebar;
