
		import Async from "../../node_modules/.pnpm/@preact+async-loader@3.0.1_preact@10.10.6/node_modules/@preact/async-loader/async.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!../../node_modules/.pnpm/babel-loader@8.2.5_7uc2ny5pnz7ums2wq2q562bf6y/node_modules/babel-loader/lib/index.js??ref--4!./Rent.js");
				typeof cb === 'function' && cb(result);
			}, "route-Rent");
		}

		export default Async(load);
	