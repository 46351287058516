import 'spectre.css/dist/spectre.min.css'
import 'spectre.css/dist/spectre-icons.min.css'
import './style/index.css';

import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'

import App from './app';

const uri = (window?.location?.hostname === 'mikenet.dcxv.com') ? 'wss://mikenet.dcxv.com/graphql' : 'ws://localhost:3031/graphql'

const link = new WebSocketLink({
    uri,
    options: {
        lazy: true,
        reconnect: true,
        timeout: 30000,
        connectionParams: () => ({
            token: localStorage.getItem('beauth')
        })
    }
})

const client = new ApolloClient({
    link,
    cache: new InMemoryCache()
})

const Main = () => (
    <ApolloProvider client={client}>
        <App link={link} />
    </ApolloProvider>
)

export default Main;
