import { h } from 'preact';
import { useEffect } from 'preact/hooks'
import { useMutation } from '@apollo/client'

import Q from '../q'
import QRCode from "react-qr-code";

const keyRegexp = /secret=([A-Z0-9]+)/;

const Modal2FAuth = ({ setModalOpen, setUser }) => {
    const [setUp2f, { data: { userSetup2fa } = {} }] = useMutation(Q.M_USETUP_2FA)
    useEffect(() => {
        setUp2f()
    }, [])

    const sendPin = e => {
        e?.currentTarget?.value?.length == 6 && setUp2f({
            variables: {
                pin: e.currentTarget.value
            }
        })
    }

    if (userSetup2fa?.status === 'DONE') {
        localStorage.setItem('otp', true)
        setUser({
            token: localStorage.getItem('beauth'),
            nick: localStorage.getItem('nick'),
            id: localStorage.getItem('id'),
            login: localStorage.getItem('login'),
            otp: true,
        })
        setModalOpen(false)
    }

    return <div class="columns">
        {
            userSetup2fa?.status !== 'DONE'
            &&
            userSetup2fa?.uri
            && <div class="column col-ml-auto text-center"><QRCode size={128} value={userSetup2fa.uri} /></div>
        }
        {
            (userSetup2fa?.status === 'INIT'
                || userSetup2fa?.status === 'NOPIN')
            &&
            <div class="column col-mr-auto">
                <div class="input-group col-xs-11">
                    <span class="input-group-addon">PIN:</span>
                    <input onChange={sendPin} type="text" class="form-input" placeholder="000000" />
                </div>

                <br/>

                {userSetup2fa?.uri && <div class="mt-4 text-tiny">KEY: {userSetup2fa?.uri?.match(keyRegexp)[1]}</div>}
            </div>
        }
        {
            userSetup2fa?.status === 'INCORRECT' &&
            <div class="column col-mr-auto my-2">
                <div class="label label-error col-mx-auto" >ERROR: INCORRECT PIN</div>
            </div>
        }
        {
            userSetup2fa?.status === 'DONE' &&
            <div class="col-mr-auto my-2" >
                <div class="label label-success" >DONE!</div>
            </div>
        }
    </div>
}

export default Modal2FAuth