import { h } from 'preact'
import { useSubscription } from '@apollo/client'
import Q from '../q'

const Subs = () => {

	const { loading } = useSubscription(Q.S_ITEM_UPD, {
		onSubscriptionData(sData) {
			const item = sData.subscriptionData.data.itemUpd
			if (item.__typename == "Transfer") {
				if (item.__typename == "Transfer" && !item.archived && !item.updated) {
					let { transferEvery } = sData?.client?.cache?.readQuery({
						query: Q.Q_TRANSFERS_EVERY, variables: {
							archived: false
						}
					})
					if (transferEvery) {
						sData.client.cache.writeQuery({
							query: Q.Q_TRANSFERS_EVERY,
							variables: { archived: false },
							data: {
								transferEvery: {
									__typename: "Transfers",
									items: [item].concat(transferEvery.items),
									total: transferEvery.total + 1
								}
							},
						})
					}
				} else {
					let data = sData?.client?.cache?.readQuery({
						query: Q.Q_TRANSFERS_EVERY, variables: {
							archived: true
						}
					})
					if (data?.transferEvery) {
						sData.client.cache.writeQuery({
							query: Q.Q_TRANSFERS_EVERY,
							variables: { archived: true },
							data: {
								transferEvery: {
									__typename: "Transfers",
									items: [item].concat(data?.transferEvery.items),
									total: data?.transferEvery.total + 1
								}
							},
						})
					}

				}
			}
			if (!item.updated) {
				switch (item.__typename) {
					case "Net":
						let d = sData.client.cache.readQuery({ query: Q.Q_EVERY })
						if (d) {
							sData.client.cache.writeQuery({
								query: Q.Q_EVERY,
								data: {
									netEvery: [item].concat(d.netEvery),
									nodeEvery: d.nodeEvery,
									servEvery: d.servEvery,
									peerEvery: d.peerEvery,
								},
							})
						}
						break
					case "Serv":
						let { servEvery } = sData.client.cache.readQuery({ query: Q.Q_SERV_EVERY })
						if (servEvery) {
							sData.client.cache.writeQuery({
								query: Q.Q_SERV_EVERY,
								data: {
									servEvery: [item].concat(servEvery),
								},
							})
						}
						break
					case "Node":
						let { nodeEvery } = sData.client.cache.readQuery({ query: Q.Q_NODE_EVERY })
						if (nodeEvery) {
							sData.client.cache.writeQuery({
								query: Q.Q_NODE_EVERY,
								data: {
									nodeEvery: [item].concat(nodeEvery),
								},
							})
						}
						break
					case "Peer":
						let data = sData.client.cache.readQuery({ query: Q.Q_EVERY })
						if (data) {
							sData.client.cache.writeQuery({
								query: Q.Q_EVERY,
								data: {
									peerEvery: [item].concat(data.peerEvery),
									nodeEvery: data.nodeEvery,
									servEvery: data.servEvery,
									netEvery: data.netEvery,
								},
							})
						}
						break
				}
			}
		}
	})
	return <></>
}

export default Subs