const formatDtToYYYYMMDD = ms => {
  let dt = new Date(ms)
  return dt.getFullYear() + '-' + ('0' + (dt.getMonth() + 1)).slice(-2) + '-' + ('0' + dt.getDate()).slice(-2)
}

const ipRegExp = /(((25[0-5]|2[0-4]\d|[0-1]?\d?\d)\.){3}(25[0-5]|2[0-4]\d|[0-1]?\d?\d))/gm

const checkTextEvent = e => e?.target?.tagName === 'BUTTON' || e?.currentTarget?.tagName === 'BUTTON' || e?.key == 'Enter'

const transferStatuses = {
  IN_PROGRESS: 'In progress',
  ESCROW_OPENED: 'Escrow opened',
  FUNDS_SECURED: 'Funds secured',
  TICKET_CREATED: 'Ticket created',
  TRANSFER_COMPLETE: 'Transfer complete',
  COMMISSION_RECEIVED: 'Commision received'
}

const rentStatuses = {
  TEST: 'In test',
  UNPAID: 'Unpaid',
  PAID: 'Paid',
  HOLD: 'On hold'
}

const servAction = {
  START: {
    ico: 'start.png',
    caption: 'start',
    if_done: 'server started'
  },
  STOP: {
    ico: 'stop.png',
    caption: 'stop',
    if_done: 'server stopped'
  },
  RESTART: {
    ico: 'restart.png',
    caption: 'restart',
    if_done: 'server restarted'
  },
  REINSTALL: {
    ico: 'reinstall.png',
    caption: 'reinstall',
    if_done: 'reinstalled'
  },
  RESET_PASS: {
    ico: 'reset_pw.png',
    caption: 'reset password',
    if_done: 'password has been reset'
  },
  RESET_FIREWALL: {
    ico: 'reset_firewall.png',
    caption: 'reset firewall',
    if_done: 'firewall has been reset'
  }
}

const currSigns = {
  USD: '\u0024',
  EUR: '\u20AC'
}

export { formatDtToYYYYMMDD, ipRegExp, checkTextEvent, transferStatuses, rentStatuses, servAction, currSigns }

