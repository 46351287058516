import { gql } from '@apollo/client'

const F_TRANSFER_ITEM = gql`fragment TransferItem on Transfer {
  id
  created
  updated
  archived
  range
  seller {
      name
      price
      notice
  }
  buyer {
      name
      price
      notice
  }
  brokers {
      name
      price
      notice
  }
  escrowId
  escrowFee
  escrowNotice
  profit
  profitNotice
  currency
  status
  statusLog {
      date
      status
  }
  ticket
}`

const F_NET_ITEM = gql`fragment NetItem on Net {
  id
  net
  created
  updated
  archived
  announced
  expire
  paid
  peer {
    id
    title
    ourAS
    peerAS
    node {
      id
      title
      ips_mask
      gw
      servs {
          id
          title
      }
    }
  }
  routes {
    serv {
      id
      created
      updated
      nodeId
      nodeTitle
      mode
      title
      ip
      tunlocal
      local
      tunremote
      tunType
      remote
      pw
      sshkey
      os
      osver
      disk
      ram
      cpu
      client
      active
      status
      info
    }
    route
  }
  bgp_announce
  whois_routes
  announce {
    id
    dt
    ok
    whois_route
    whois_origin
    bgpview_as
    rpki
  }
  status
  info
  bgp_vis
}`

const F_SERV_ITEM = gql`fragment ServItem on Serv {
  id
  routes {
    route
  }
  created
  updated
  archived
  nodeId
  nodeTitle
  mode
  title
  ip
  local
  remote
  tunlocal
  tunremote
  tunType
  rx
  pw
  sshkey
  sshport
  os
  osver
  disk
  ram
  cpu
  client
  active
  status
  info
  state
}`

const F_PEER_ITEM = gql`fragment PeerItem on Peer {
  id
  node {
    id
    ips_mask
    title
    gw
  }
  peerIP
  peerAS
  title
  ourIP
  ourAS
  updated
  created
  status
  info
  archived
}`

const F_RENT_ITEM = gql`fragment RentItem on Rent {
  id
  created
  updated
  archived
  range
  owner
  client
  in_price
  in_curr
  out_price
  out_curr
  period_from
  period_to
  status    
}`


export default {
  M_LOGIN: gql`mutation($login: String!, $pw: String!, $pin: String) {
    loginUser(login: $login, pw: $pw, pin: $pin) {
      id
      otp
      nick
      admin
      login
      token
    }
  }`,

  Q_USER: gql`query($id: Int!) {
    userId(id: $id) {
      id
      otp
      admin
      token
      login
      nick
    }
  }`,

  Q_USERS: gql` {
    userEvery {
      id
      otp
      admin
      token
      login
      nick
    }
  }`,

  Q_NODE_EVERY: gql`query{
    nodeEvery {
      id
      free_ips
      ip
      port
      ips_mask
      created
      updated
      title
      ips
      archived
      peers {
          id
          title
          ourAS
          peerAS
      }
      status
      info
      gw
    }
  }`,

  Q_EVERY: gql`query ($archived: Boolean, $sortBy: String) {
    nodeEvery {
      id
      free_ips
      ip
      archived
      ips_mask
      port
      title
      gw
      peers {
        id
        peerIP
        peerAS
        ourIP
        ourAS
        title
      }
    }
    peerEvery {
        ...PeerItem
    }
    servEvery {
        ...ServItem
    }
    netEvery(archived: $archived, sortBy: $sortBy) {
        ...NetItem
    }
  } ${F_NET_ITEM} ${F_SERV_ITEM} ${F_PEER_ITEM}`,

  Q_SERV_EVERY: gql`query {
    servEvery {
      ...ServItem
    }
  } ${F_SERV_ITEM}`,

  Q_NET_EVERY: gql`query{
    netEvery {
      ...NetItem
    }
  } ${F_NET_ITEM}`,

  Q_PEER_EVERY: gql`query{
    peerEvery {
      ...PeerItem
    }
  } ${F_PEER_ITEM}`,

  Q_TRANSFERS_EVERY: gql`query ($archived: Boolean, $sortBy: String) {
    transferEvery (archived: $archived, sortBy: $sortBy) {
      total
      items {
        ...TransferItem
      }
    }
  }${F_TRANSFER_ITEM}`,

  Q_TRANSFER_ID: gql`query($id: Int!) {
    transferId(id: $id) {
      ...TransferItem
    }
  }${F_TRANSFER_ITEM}`,

  Q_RENTS_EVERY: gql`query ($archived: Boolean, $sortBy: String) {
    rentEvery(archived: $archived, sortBy: $sortBy) {
      total
      items {
        ...RentItem
      }
    }
  }${F_RENT_ITEM}`,

  Q_RENT_ID: gql`query($id: Int!) {
    rentId(id: $id) {
      ...RentItem
    }
  }${F_RENT_ITEM}`,

  Q_NET: gql`query($id: Int!) {
    netId(id: $id) {
      ...NetItem
    }
  } ${F_NET_ITEM}`,

  Q_NODE_ID: gql`query($id: Int!){
    nodeId(id: $id){
      id
      archived
      free_ips
      ips_mask
      created
      updated
      title
      ip
      port
      ips
      peers {
          id
          title
          ourAS
          peerAS
          node {
              id
              title
              gw
              ips_mask
              servs {
                  ...ServItem
              }
          }
      }
      gw
    }
  } ${F_SERV_ITEM}`,

  Q_NET_WHO_IS: gql`query($id: Int!){
        netWhois(id: $id)
    }`,

  Q_NET_BGP_VIEW: gql`query($id: Int!){
        netBGPView(id: $id)
    }`,

  Q_BGPVIEW: gql`query($net: String!){
        bgpview(net: $net)
    }`,

  Q_SEARCH: gql`query($input: SearchInput){
    search(input: $input) {
      total
      items {
        ... on Net {
            ...NetItem
        }
        ... on Serv {
            ...ServItem
        }
        ... on Node {
          id
          created
          updated
          title
          ip
          archived
          title
          port
          ips
          peers {
              id
          }
          servs {
              ...ServItem
          }
          ips_mask
          status
          info
          gw
        }
        ... on Peer {
            ...PeerItem
        }
        ... on Transfer {
            ...TransferItem
        }
      }
    }
  } ${F_TRANSFER_ITEM} ${F_SERV_ITEM} ${F_PEER_ITEM} ${F_NET_ITEM}`,

  Q_ORG_SEARCH: gql`query($name: String) {
        orgSearch(name: $name)
    }`,

  M_ADD_NET: gql`mutation($id: Int, $input: NetInput){
        netAddMod(id: $id, input: $input){
            ...NetItem
        }
    } ${F_NET_ITEM}`,

  M_ADD_PEER: gql`mutation($id: Int, $input: PeerInput){
        peerAddMod(id: $id, input: $input){
            ...PeerItem
        }
    } ${F_PEER_ITEM}`,

  M_ADD_MODE: gql`mutation($id: Int, $input: NetInput){
        nodeAddMod(id: $id, input: $input){
            id
            archived
            created
            updated
            title
            ip
            ips
            port
            status
            info
            gw
            ips_mask
        }
    }`,

  M_ADD_SERV: gql`mutation($id: Int, $input: ServInput){
    servAddMod(id: $id, input: $input){
      ...ServItem
    }
  } ${F_SERV_ITEM}`,

  M_ADD_NODE: gql`mutation($id: Int, $input: NodeInput){
    nodeAddMod(id: $id, input: $input){
      id
      created
      archived
      updated
      title
      ip
      title
      port
      ips
      peers {
          id
      }
      servs {
          ...ServItem
      }
      ips_mask
      status
      info
      gw
    }
  } ${F_SERV_ITEM}`,

  M_REM_NODE: gql`mutation($id: Int){
    nodeRem(id: $id)
  }`,

  M_REM_PEER: gql`mutation($id: Int!){
    peerRem(id: $id)
  }`,

  M_REM_NET: gql`mutation($id: Int!, $unRem: Boolean){
    netRem(id: $id, unRem: $unRem) {
      ...NetItem
    }
  } ${F_NET_ITEM}`,

  M_REM_SERV: gql`mutation($id: Int!){
    servRem(id: $id)
  }`,

  M_ADD_NET_ROUTE: gql`mutation($net: Int!, $route: String!, $serv: Int!){
    netRouteAdd(net: $net, route: $route, serv: $serv) {
      ...NetItem
    }
  } ${F_NET_ITEM}`,

  M_NET_WHOIS_ROUTES: gql`mutation($id: Int!, $routes: [String]!){
        netWhoisRoutes(id: $id, routes: $routes) {
            ...NetItem
        }
    } ${F_NET_ITEM}`,

  M_REM_NET_ROUTE: gql`mutation($net: Int!, $route: String!){
        netRouteRem(net: $net, route: $route) {
            ...NetItem
        }
    } ${F_NET_ITEM}`,

  M_USER_ADD_MOD: gql`mutation($input: UserAddModInput, $id: Int) {
        userAddMod(input: $input, id: $id) {
            id
            otp
            nick
            login
            pw
            token
        }
    }`,

  M_NODE_CHECK: gql`mutation($id: Int!) {
    nodeCheck(id: $id) {
      id
      ip
      port
      created
      updated
      archived
      gw
      ips_mask
      title
      ips 
      peers {
          id
      }
      status
      info
    }
  }`,

  M_PEER_CHECK: gql`mutation($id: Int!) {
        peerCheck(id: $id) {
            ...PeerItem
        }
    } ${F_PEER_ITEM}`,

  M_SERV_CHECK: gql`mutation($id: Int!) {
        servCheck(id: $id) {
            ...ServItem
        }
    } ${F_SERV_ITEM}`,

  M_NET_CHECK: gql`mutation($id: Int!) {
        netCheck(id: $id) {
            ...NetItem
        }
    } ${F_NET_ITEM}`,

  M_NET_ANNOUNCE_CHECK: gql`mutation($id: Int!) {
        netAnnounceCheck(id: $id) {
            ...NetItem
        }
    } ${F_NET_ITEM}`,

  M_ROUTE_CHECK: gql`mutation($net: Int!) {
        netRouteCheck(net: $net) {
            ...NetItem
        }
    } ${F_NET_ITEM}`,

  M_REM_USER: gql`mutation($id: Int!) {
        userRem(id: $id)
    }`,

  M_TRANSFER_ADD_MOD: gql`mutation($id: Int, $input: TransferInput) {
    transferAddMod(id: $id, input: $input) {
      ...TransferItem
    }
  } ${F_TRANSFER_ITEM}`,

  M_TRANSFER_REM: gql`mutation($id: Int!) {
    transferRem(id: $id) {
      id
      archived
    }
  }`,

  M_RENT_ADD_MOD: gql`mutation($id: Int, $inp: RentInput!) {
    rentAddMod(id: $id, inp: $inp) {
      ...RentItem
    }
  } ${F_RENT_ITEM}`,

  M_RENT_REM: gql`mutation($id: Int!) {
    rentRem(id: $id) {
      id
      archived
    }
  }`,

  M_RENT_RENEW: gql`mutation($id: Int!) {
    rentRenew(id: $id) {
      ...RentItem
    }
  } ${F_RENT_ITEM}`,

  M_SERV_ACTION: gql`mutation($id: Int!, $action: ServAction, $payload: String) {
        servAction(id: $id, action: $action, payload: $payload) {
            ...ServItem
        }
    } ${F_SERV_ITEM}`,

  M_USETUP_2FA: gql`mutation($pin: String) {
        userSetup2fa(pin: $pin) {
            status 
            # INIT NOPIN INCORRECT DONE
            uri
        }
    }`,

  S_ITEM_UPD: gql`subscription{
    itemUpd {
      ... on Net {
        ...NetItem
      }
      ... on Serv {
        ...ServItem
      }
      ... on Node {
        id
        created
        updated
        title
        ip
        title
        archived
        port
        ips
        peers {
          id
        }
        servs {
          ...ServItem
        }
        ips_mask
        status
        info
        gw
      }
      ... on Peer {
        ...PeerItem
      }
      ... on Transfer {
        ...TransferItem
      }
    }
  } ${F_TRANSFER_ITEM} ${F_SERV_ITEM} ${F_PEER_ITEM} ${F_NET_ITEM}`,

}