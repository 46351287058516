import { h } from 'preact'
import { Router, route } from 'preact-router'
import { useState, useEffect } from 'preact/hooks'

import Header from './components/header'
import Profile from './routes/profile'

import Login from './routes/Login'
import Nets from './routes/Nets'
import Nodes from './routes/Nodes'
import Peers from './routes/Peers'
import Servers from './routes/Servers'
import Transfers from './routes/Transfers'
import Rent from './routes/Rent'

// import Search from './routes/Search'
import Sidebar from './components/sidebar'
import Modal from './components/modal'
import Modal2FAuth from './components/2fAuthModal'
import Net from './routes/Net'
import Subs from './components/subs'

const App = ({ link }) => {

  const [user, setUser] = useState(() => ({
    token: localStorage.getItem('beauth'),
    nick: localStorage.getItem('nick'),
    id: localStorage.getItem('id'),
    login: localStorage.getItem('login'),
    otp: localStorage.getItem('otp'),
  }))
  const [modal2F, set2FModalOpen] = useState(false)

  const updateUser = (user) => {
    // console.log('user to upd --> ', user)
    localStorage.setItem('beauth', user.token)
    user?.otp && localStorage.setItem('otp', user.otp)
    localStorage.setItem('nick', user.nick)
    localStorage.setItem('id', user.id)
    localStorage.setItem('login', user.login)
    link.subscriptionClient.close(false, false)
    setUser(user)
    setTimeout(() => route('/networks', true), 500)
  }

  const logout = (e) => {
    e.preventDefault()
    localStorage.clear()
    link.subscriptionClient.close(false, false)
    setUser({ token: false, nick: false })
    setTimeout(() => route('/', true), 500)
  }

  useEffect(() => {
    if (user?.token && !user?.otp) {
      set2FModalOpen(true)
    }
  }, [user])

  return (
    <div id="app">
      <Header user={user} logout={logout} />
      {user?.token ?
        <Sidebar user={user} logout={logout}>
          <>
            {modal2F && !user?.otp &&
              <Modal
                title="Setup the 2-factor authentication, please:"
                setModalOpen={set2FModalOpen}
                isModalOpen={modal2F}>
                <Modal2FAuth setModalOpen={set2FModalOpen} setUser={setUser} />
              </Modal>
            }
          </>

          <Router>
            <Nets path="/" exact />
            <Nets path="/networks" exact />
            <Nets path="/networks/:search" />
            
            <Net path="/net/:id" />

            <Servers path="/servers" exact />
            <Servers path="/servers/:search" />
            
            <Nodes path="/nodes" exact />
            <Nodes path="/nodes/:search" />
            
            <Peers path="/peerings" exact />
            <Peers path="/peerings/:search" />
            
            <Transfers path="/transfers" exact />
            <Transfers path="/transfers/:search" />
            
            <Rent path="/rent" exact />
            <Rent path="/rent/:search" />

            <Profile path="/profile/:user" setUser={setUser} u={user} />
          </Router>

          <Subs />
        </Sidebar>
        :
        <div id="content" style={{ marginTop: '2rem' }}>
          <Login updateUser={updateUser} user={user} />
        </div>
      }
    </div>
  )
}

export default App
